<template>
    <LiefengContent>
        <template v-slot:title>{{ $route.query.menuName || "便民服务" }}</template>
        <template v-slot:toolsbarLeft>
            <Select v-model="catType" style="width: 120px; margin-right: 5px" placeholder="类型">
                <Option :value="item.dictKey" v-for="(item, index) in selectList" :key="index">{{ item.dictValue }}</Option>
            </Select>
            <Button
                type="info"
                @click="
                    () => {
                        getSceneData(1)
                    }
                "
            >
                查询
            </Button>
            <Button
                type="default"
                @click="
                    () => {
                        catType = ''
                        getSceneData(1)
                    }
                "
            >
                清空条件
            </Button>
        </template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})" >新增</Button>
            <!-- <Button type="info" @click="importExcelBtn">导入</Button> -->
        </template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="menuList"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="onSelect"
                ></LiefengTable>
            </div>
            <!-- 导入 -->
            <LiefengModal title="导入" width="40%" height="240px" :value="exportExcel.status" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea>
                    <LiefengUploadExcel
                        style="margin: 20px 0"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/15mintesttemplate.xlsx', downName: '十五分钟核酸圈导入模板' }"
                        :action="exportExcel.exportAction"
                        :uploadData="uploadData"
                        :isShow="exportExcel.showExcel"
                        @success="excelSuccess"
                    ></LiefengUploadExcel>
                </template>
            </LiefengModal>

            <LiefengModal class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度" :value="mapStatus" @input="mapFn">
                <template v-slot:contentarea>
                    <div style="width: 400px; position: absolute; margin: 10px; z-index: 100; display: flex; background: #ffffff; padding: 10px; border-radius: 10px">
                        <Input v-model="keyword" style="width: 100%; margin-right: 5px" placeholder="请输入地名"></Input>
                        <Button type="primary" @click="setPlace(keyword)">查询</Button>
                    </div>
                    <baidu-map
                        :center="pageCenter.lng == null ? { lng: 113.263, lat: 23.115 } : pageCenter"
                        :zoom="zoom"
                        @ready="handler"
                        :ak="$store.state.baiduAk"
                        :clicking="true"
                        @click="clickMap"
                        :scroll-wheel-zoom="true"
                    >
                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                        <bm-view style="width: 100%; height: 470px; flex: 1"></bm-view>
                    </baidu-map>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="mapFn(false)">取消</Button>
                    <Button type="primary" @click="selectMap">确定</Button>
                </template>
            </LiefengModal>

            <LiefengModal :title="currentFormData.pointId ? '修改' : '新增'" width="70%" height="500px" :value="addModal.status" @input="addModalData" class="export-modal">
                <template v-slot:contentarea>
                    <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                        <FormItem label="ID" v-if="currentFormData.id">
                            <Input disabled v-model.trim="currentFormData.id"></Input>
                        </FormItem>
                        <FormItem label="名称" prop="pointName">
                            <Input v-model.trim="currentFormData.pointName" placeholder="请填写名称" maxlength="100"></Input>
                        </FormItem>
                        <FormItem label="地址" prop="pointAddr">
                            <Input v-model.trim="currentFormData.pointAddr" placeholder="请填写详细地址" maxlength="50"></Input>
                        </FormItem>
                        <FormItem v-if="!mapStatus">
                            <span slot="label" class="validate">经纬度:</span>
                            <Input v-model.trim="currentFormData.lng" placeholder="经度" style="width: 120px; margin-right: 20px"></Input>
                            <Input v-model.trim="currentFormData.lat" placeholder="纬度" style="width: 120px; margin-right: 20px"></Input>
                            <Button @click="mapFn(true)">地图选点</Button>
                        </FormItem>
                        <FormItem>
                            <span slot="label" class="validate">类型:</span>
                            <RadioGroup v-model="currentFormData.catId">
                                <Radio v-for="(item, index) in selectList" :key="index" :label="item.dictKey">{{ item.dictValue }}</Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem label="联系电话">
                            <Input v-model.trim="currentFormData.pointMobile" placeholder="请填写电话" maxlength="50"></Input>
                        </FormItem>
                        <FormItem label="收费">
                            <Input type="textarea" v-model.trim="currentFormData.fee" placeholder="如：1元/时"></Input>
                        </FormItem>
                        <FormItem label="描述">
                            <iframe
                                v-if="addModal.status"
                                name="ueditor"
                                id="ueditor"
                                src="/mobileeditor/#/index?titlename=描述"
                                style="width: 100%; height: 200px; border: 0px"
                                @load="loadUeditor"
                            ></iframe>
                            <!-- <Input type="textarea" v-model.trim="currentFormData.pointDesc" placeholder="可详细描述当前位置情况，限500字"></Input> -->
                        </FormItem>
                        <FormItem label="现场图">
                            <LiefengUpload
                                ref="LiefengUploadIcon"
                                v-if="showImage"
                                accept=".jpg,.png,.gif,.jpeg"
                                :format="['jpg', 'png', 'jpeg', 'gif']"
                                :defaultList="currentFormData.pointImage"
                                :showView="true"
                                :multiple="true"
                                :fileLength="1000"
                            ></LiefengUpload>
                        </FormItem>
                        <FormItem label="语音文件">
                            <LiefengUploadAudio v-if="showAudio" ref="LiefengUploadAudio" @success="uploadAudio"></LiefengUploadAudio>
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="addModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="submit">保存</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUploadExcel from "@/components/LiefengUploadExcel"
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"
import LiefengUpload from "@/components/LiefengUpload"
import LiefengUploadAudio from "@/components/LiefengUploadAudio"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUploadExcel,
        BaiduMap,
        BmMarker,
        BmView,
        BmNavigation,
        LiefengUpload,
        LiefengUploadAudio,
    },
    data() {
        return {
            mapStatus: false,
            zoom: 16,
            pageCenter: {},
            selectedLocation: {},
            BMap: null,
            map: null,
            keyword: "",
            menuList: [],
            selection: [],
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    type: "selection",
                    width: 60,
                    key: "id",
                    align: "center",
                },
                {
                    title: "名称",
                    width: 250,
                    key: "pointName",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: { color: "blue", cursor: "pointer" },
                                on: {
                                    click: async () => {
                                        await this.select(params.row.pointId)
                                        this.addModalData(true)
                                    },
                                },
                            },
                            params.row.pointName
                        )
                    },
                },
                {
                    title: "地址",
                    minWidth: 200,
                    key: "pointAddr",
                    align: "center",
                },
                {
                    title: "类型",
                    minWidth: 200,
                    key: "catName",
                    align: "center",
                },
                // {
                //     title: "添加人",
                //     minWidth: 200,
                //     key: "creatorName",
                //     align: "center",
                // },
                // {
                //     title: "添加账号",
                //     minWidth: 200,
                //     key: "gmtModified",
                //     align: "center",
                // },
                {
                    title: "添加时间",
                    key: "gmtCreate",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? (params.row.gmtCreate = this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss")) : "")
                    },
                },
                {
                    title: "访问量",
                    minWidth: 200,
                    key: "view",
                    align: "center",
                },
                {
                    title: "操作",
                    align: "center",
                    width: 180,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                await this.select(params.row.pointId)
                                                this.addModalData(true)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.deleteData(params.row.pointId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "删除" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: "访问纪录",
                                                    content: `/page#/convenientrecord?scenicId=${params.row.scenicId}&pointId=${params.row.pointId}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "访问纪录" //1001
                                ),
                            ]
                        )
                    },
                },
            ],
            buttonProps: {
                type: "default",
                size: "small",
            },
            currentFormData: {},
            defaultList: [],
            validateForm: {
                pointName: [{ required: true, message: "请输入名称", trigger: "blur" }],
                pointAddr: [{ required: true, message: "请输入地址", trigger: "blur" }],
            },
            exportExcel: {
                status: false,
                exportAction: "",
                showExcel: 0,
            },

            addModal: {
                status: false,
            },
            showImage: false,

            selectList: [],

            catType: "",
            showAudio: false,
            scenicId: "",
        }
    },
    methods: {
        loadUeditor() {
            if (this.currentFormData.pointDesc != "" && this.currentFormData.pointDesc != null && this.currentFormData.pointDesc != undefined) {
                var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
                v_Obj.setContent(this.currentFormData.pointDesc) //写入编辑器富文本内容
            } else {
                setTimeout(this.loadUeditor, 300)
            }
        },
        // 音频上传成功
        uploadAudio(val) {
            console.log("val", val)
            if (val && val.length) {
                this.currentFormData.pointAudio = val[0].url
            } else {
                this.currentFormData.pointAudio = ""
            }
        },
        // 图片上传成功
        uploadSuccess(val) {
            this.currentFormData.pointImage = val
        },
        // 点击导入按钮
        importExcelBtn() {
            this.exportExcel.status = true
            this.exportExcel.showExcel++
            //  this.exportExcel.exportAction = "http://localhost:808/api/o2o/test15min/import";
            this.exportExcel.exportAction = window.vue.getProxy()["/gateway"].target + "/apps/o2o/api/o2o/test15min/import"
        },
        // 关闭导入弹窗事件
        excelModalData(status) {
            this.exportExcel.status = status
        },
        addModalData(status) {
            this.addModal.status = status
        },
        //导入成功的回调
        excelSuccess() {
            this.exportExcel.status = false
            this.getSceneData(1)
        },

        submit() {
            var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
            this.currentFormData.pointDesc = v_Obj.getContent() //获取富文本内容

            this.currentFormData.pointImage =
                this.$refs.LiefengUploadIcon.uploadList && this.$refs.LiefengUploadIcon.uploadList.length
                    ? (() => {
                          let list = []
                          this.$refs.LiefengUploadIcon.uploadList.map(item => {
                              list.push(item.url)
                          })
                          return list.join(",")
                      })()
                    : ""
            this.$refs.form.validate(status => {
                if (status) {
                    if (!this.currentFormData.lng || !this.currentFormData.lat) {
                        this.$Message.error({
                            background: true,
                            content: "经纬度必填",
                        })
                        return
                    }
                    if (!this.currentFormData.catId || this.currentFormData.catId == "") {
                        this.$Message.error({
                            background: true,
                            content: "请选择类型",
                        })
                        return
                    }

                    var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
                    delete cFormData.gmtCreate
                    delete cFormData.status
                    this.$post(
                        "/gateway/api/syscenic/app/point/createOrUpdate",
                        {
                            ...cFormData,
                            scenicId: this.$route.query.scenicId || this.scenicId,
                            lgt: cFormData.lng,
                            // pointId:'',
                            // pointIcon:''
                        },
                        { "content-type": "application/json" }
                    )
                        .then(res => {
                            this.addModalData(false)
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "保存成功",
                                })
                                this.currentFormData = {}
                                this.getSceneData(this.page)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "保存失败，请联系管理员处理",
                            })
                        })
                }
            })
        },

        append(data) {
            this.addModalData(true)
            this.defaultList = []
            this.currentFormData = {
                id: data.id,
                pointImage: "",
                catId: "",
                pointAudio: "",
                pointDesc: "",
            }
            this.showImage = false
            this.showAudio = true
            this.$nextTick(() => {
                this.showImage = true
                this.$refs.LiefengUploadAudio.uploadArr = []
            })
            this.loadUeditor()
        },
        getBCTitle(id, obj) {
            if (obj.id == id) {
                this.bcTitle.unshift(obj.title)
                this.getBCTitle(obj.parentId, this.menuList[0])
            } else if (obj.children && obj.children.length > 0) {
                for (let k in obj.children) {
                    this.getBCTitle(id, obj.children[k])
                }
            }
        },
        async select(id) {
            try {
                this.showImage = false
                this.showAudio = false
                this.$nextTick(() => {
                    this.showAudio = true
                })
                if (id) {
                    this.currentFormData = {}
                    await this.$get("/gateway/api/syscenic/app/point/queryById", {
                        pointId: id,
                    })
                        .then(async res => {
                            if (res.code == 200 && res.data) {
                                var cFormData = res.data
                                cFormData.lng = res.data.lgt
                                if (cFormData.pointImage && cFormData.pointImage != "") {
                                    var list = []

                                    cFormData.pointImage.split(",").map(item => {
                                        list.push({ url: item, name: "" })
                                    })
                                }
                                cFormData.pointImage = list
                                this.currentFormData = cFormData
                                this.$nextTick(() => {
                                    this.showImage = true
                                })
                                if (cFormData.pointAudio && cFormData.pointAudio != "") {
                                    this.$nextTick(() => {
                                        this.$refs.LiefengUploadAudio.uploadArr = [{ name: cFormData.pointAudio.split("/")[cFormData.pointAudio.split("/").length - 1], url: cFormData.pointAudio }]
                                    })
                                }
                                this.$forceUpdate()
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "获取数据失败，请联系管理员处理",
                            })
                        })
                }
            } catch (err) {
                console.log("err", err)
            }
        },
        onSelect(selection) {
            this.selection = selection
        },
        deleteData(id) {
            this.$Modal.confirm({
                title: "删除确认",
                content: "是否删除选中的数据？",
                onOk: () => {
                    this.$post("/gateway/api/syscenic/app/point/delete", {
                        pointId: id,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.getSceneData(1)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: "删除出错：" + res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            this.$Message.error({
                                background: true,
                                content: "删除出措",
                            })
                        })
                },
            })
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.getSceneData(obj.page)
        },
        async getSceneData(page) {
            this.selection = []
            this.loading = true
            await this.$get("/gateway/api/syscenic/pc/point/listPointByPage", {
                page: page,
                pageSize: this.pageSize,
                catId: this.catType, // 类型
                scenicId: this.$route.query.scenicId || this.scenicId, // 1景区、2 便民便利
                lat: "",
                lgt: "",
                catType: this.$route.query.type || "2",
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList
                        this.page = res.currentPage
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 点击了地图
        clickMap(e) {
            this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
        },
        handler({ BMap, map }) {
            this.BMap = BMap
            this.map = map
        },
        makerCenter(point) {
            if (this.map) {
                this.selectedLocation = point
                this.map.clearOverlays()
                this.map.addOverlay(new this.BMap.Marker(point))
            }
        },
        mapFn(status) {
            this.mapStatus = status
            if (this.currentFormData.lng) {
                this.keyword = ""
                this.pageCenter = { lng: this.currentFormData.lng, lat: this.currentFormData.lat }
                this.makerCenter(this.pageCenter)
            } else if (this.currentFormData.pointAddr) {
                this.keyword = this.currentFormData.pointAddr
                this.setPlace(this.currentFormData.pointAddr)
            }
        },
        selectMap() {
            this.currentFormData.lng = this.selectedLocation.lng
            this.currentFormData.lat = this.selectedLocation.lat
            this.mapStatus = false
        },
        setPlace(myValue) {
            if (!myValue) return
            var that = this
            var local = new this.BMap.LocalSearch(this.map, {
                onSearchComplete: () => {
                    try {
                        var pp = local.getResults().getPoi(0).point
                        that.pageCenter = { lng: pp.lng, lat: pp.lat }
                        that.makerCenter(pp)
                    } catch (e) {}
                },
            })
            local.search(myValue)
        },
        chaneTime(e) {
            console.log(e)
        },

        async getDictTye() {
            await this.$get("/dictlist/api/syscenic/app/cat/selectByScenic", {
                scenicId: this.$route.query.scenicId || this.scenicId,
                catType: this.$route.query.type || "2",
            }).then(res => {
                if (res.code == 200) {
                    this.selectList = res.dataList.map(item => {
                        return {
                            ...item,
                            dictValue: item.catName,
                            dictKey: item.catId,
                        }
                    })
                }
            })
        },

        async getOrgCode() {
            await this.$get("/gateway/api/syscenic/app/selectByOrgCode", {
                orgCode: this.$route.query.orgCode || window.sessionStorage.getItem("orgCode"),
            }).then(async res => {
                if (res && res.data && res.data != "") {
                    this.scenicId = res.data
                } else {
                    this.$Message.error({
                        content: "当前机构暂无授权",
                        background: true,
                    })
                    return
                }
            })
        },
    },
    async created() {
        await this.getSceneData(1)
        await this.getDictTye()
    },
}
</script>

<style lang='less'>
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    padding: 10px 30px 50px 10px;
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight {
    display: flex;
    padding-top: 10px;
}
</style>
